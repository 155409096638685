<template>
  <div class="row activate-page">
    <div v-if="pleaseActivateMessage" class="activation-reminder">
      <p v-if="!emailSent" class="text">
        Hi, please verify your email to post or comment. Its much appreciated!
      </p>
      <p class="text" v-if="emailSent">
        Email sent to
        <span class="text-primary">{{ $store.state.userData.email }}</span
        >! Check your inbox.
      </p>
      <p class="text" v-if="emailSent">
        If this is not your email, or you made a mistake, you can change your
        email in the
        <span @click="$router.push('/settings/profile/')" class="link"
          >profile settings</span
        >.
      </p>
      <button
        class="button button-success"
        @click="resendEmail(true)"
        :class="{ disabled: emailSent }">
        send verification email
      </button>
    </div>
    <div v-if="!pleaseActivateMessage" class="activation-messages">
      <p v-if="loading && !expiredToken" class="text">
        {{ message }}
      </p>
      <p class="text" v-if="!loading && !expiredToken">
        You're fully activated! You can now post all the cool stuff you can do!
        You'll be redirected in
        <span class="number" ref="number">{{ secondsLeft }}</span> seconds.
      </p>
      <p class="text" v-if="expiredToken && !emailSent">
        This link seems to be broken or expired. Make sure its the correct link,
        or click
        <span class="link" @click="resendEmail">here</span> to resend the
        activation email.
      </p>
      <p class="text" v-if="expiredToken && emailSent">
        Email sent! Check your inbox.
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ActivateAccount",
  data() {
    return {
      loading: true,
      message: "We're activating your account. Give us a moment.",
      emailSent: false,
      secondsLeft: 5,
      inverval: 0, //just in case user leaves page wihtout clearing it
      pleaseActivateMessage: false,
    };
  },
  mounted() {
    let params = this.$route.params;
    if (
      //user is already activated, no need to be here
      this.$store.state.isAuthenticated &&
      this.$store.state.userData.is_verified_email
    ) {
      this.$router.push("/");
      return;
    }
    if (params.id == "reminder") {
      if (!this.$store.state.isAuthenticated) {
        this.$router.push("/login"); //user should be logged in to try to resend their own email
        return;
      }
      this.pleaseActivateMessage = true;
      console.log("hi");
      return;
    }
    if (params.id && params.token) {
      //anything else might be a verification link
      this.$store.commit("setLoading", true);
      axios
        .post("/api/v1/users/activate/" + params.id + "/" + params.token + "/")
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("setLoading", false);
            this.loading = false;
            this.interval = setInterval(() => {
              this.secondsLeft -= 1;
              if (this.secondsLeft <= 0) {
                clearInterval(this.interval);
                if (this.$store.state.isAuthenticated) {
                  this.$router.push("/user/me");
                } else {
                  this.$router.push("/login");
                }
              }
            }, 1000);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.token) {
            this.expiredToken = true;
          } else {
            this.message =
              "This is an invalid link. You'll be redirected shortly.";
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }
          this.$store.commit("setLoading", false);
        });
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    resendEmail(asReminder = false) {
      if (!asReminder && this.$route.params.id) {
        axios.post(
          "/api/v1/users/activate/" +
            this.$route.params.id +
            "/resendactivationemail/"
        );
      } else if (asReminder) {
        axios.post("/api/v1/users/activate/reminder/resendactivationemail/");
      }
      this.emailSent = true;
    },
  },
  unmounted() {
    clearInterval(this.inverval);
  },
};
</script>

<style lang="scss" scoped>
.activate-page {
  min-height: 100vh;
}
.activation-reminder {
  margin: 2rem;
  max-width: 75ch;
}
.text {
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
}
.link {
  text-decoration: underline;
  color: $info-600;
  cursor: pointer;
  &:hover {
    color: $info;
  }
}
.activation-reminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button {
  width: fit-content;
}
</style>
